<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class=" primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }"
          >Dashboard
        </v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip active-class="false" :to="{ name: 'Products' }"
          >Products
        </v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'ElimuPlan' }">
          {{ $options.name }}
        </v-chip>
      </v-chip-group>
    </div>
    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
      <card-title icon="/images/icons/elimu-b.png"
        >Elimu Plan Calculator</card-title
      >
      <div class="tw-w-full tw-flex tw-flex-wrap tw-py-3 md:tw-py-6">
        <keep-alive>
          <component
            :is="current"
            :productData="productData"
            :benefits="benefits"
            :terms="terms"
            :quoteSummaryData="quoteSummaryData"
            @to-quote-summary="toQuoteSummary"
          ></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import { mapState } from 'vuex';
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import QuoteSummary from '../../components/rfq/QuoteSummary';
import ClientQuotation from '../../components/rfq/ClientQuotation';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '@/mixins/savedQuoteMixin';

export default {
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'elimu_plan_page_visited_by_agent', {
        event_category: 'Elimu plan component created',
        event_label: 'elimu plan component created',
      });
    }
  },
  mounted() {
    this.checkSavedQuote('elimu');
  },
  name: 'ElimuPlan',
  metaInfo: {
    title: 'RFQ - Elimu Plan',
  },
  components: {
    QuoteSummary,
    BackButton,
    CardTitle,
    Default,
    ClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      current: ClientQuotation,
      productData: { prodCode: 2013103, optionCode: 2012147 },
      benefits: [
        { coverName: 'Total & Permanent Disability', coverProdCode: 2013518 },
        { coverName: 'Waiver of Premium', coverProdCode: 2013517 },
        { coverName: 'Child Medical Reimbursement', coverProdCode: 2013568 },
        { coverName: 'Adult Medical Reimbursement', coverProdCode: 2013521 },
        { coverName: 'Critical Illness', coverProdCode: 2013520 },
        { coverName: 'Retrenchment', coverProdCode: 2016611 },
      ],
      terms: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      quoteSummaryData: {},
    };
  },
  computed: {
    ...mapState({
      savedQuoteStatus: (state) => state.quote.isSaved,
    }),
  },
};
</script>
